import { createI18n } from 'vue-i18n';
import en from './locales/en.yml';
import ru from './locales/ru.yml';

const messages = {
  en,
  ru,
};

// eslint-disable-next-line
const ruPluralRule = (choice, choicesLength, orgRule) => {
  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;

  if (!teen && endsWithOne) {
    return 0;
  }

  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 1;
  }

  return 2;
}

// Get the browser's language setting
const browserLanguage = navigator.language.split('-')[0];

// Check if the browser's language is supported
const supportedLanguages = Object.keys(messages);
const defaultLanguage = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en';

const i18n = createI18n({
  locale: defaultLanguage, // set locale
  pluralRules: {
    ru: ruPluralRule
  },
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
  legacy: false,
});

export default i18n;
