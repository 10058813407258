<template>
  <header class="header">
    <div class="header__box" :class="{'header__box--only-logo': !isAuth}">
      <div class="header__logo">
        <img src="@/assets/logo.svg" class="header__logo-img" />
        <Text weight="600">Karma</Text>
      </div>
      <div class="header__data" v-if="isAuth">
        <div class="header__user">
          <Avatar :imgSrc="currentUser.photo_url" />
          <Text class="header__user-name" medium bold>{{ currentUser.full_name }}</Text>
        </div>
        <LocaleMenu />
        <button class="header__logout" @click="logOut">
          <Text medium>{{ $t('auth.logout') }}</Text>
        </button>
      </div>
    </div>
  </header>
</template>

<script setup>
import { Text, Avatar } from '@sliday/karma-ui-library';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import LocaleMenu from './LocaleMenu.vue';

const store = useStore();
const router = useRouter();

const isAuth = computed(() => {
  return store.state.auth.isLoggedIn;
});

const currentUser = computed(() => {
  return store.state.auth.currentUser;
});

const logOut = async () => {
  await store.dispatch('auth/logout');
  router.push({name: 'Login'})
}
</script>

<style scoped lang="scss">
.header {
  width: 100%;
  background: #fff;
  box-shadow:
    0px 8px 60px 0px rgba(35, 75, 111, 0.04),
    0px 8px 40px 0px rgba(75, 111, 143, 0.15),
    0px 6px 10px 0px rgba(117, 145, 171, 0.02);
  padding: 7px 15px;

  &__box {
    max-width: 1140px;
    margin: 0 auto;
    min-height: 36px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--only-logo {
      justify-content: center;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    gap: 4px;
  }

  &__logo-img {
    object-fit: contain;
    width: 22px;
    height: 22px;
    border-radius: 8px;
  }

  &__data {
    display: flex;
    gap: 30px;
    align-items: center;

    @include sm {
      gap: 20px;
    }
  }

  &__user {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__user-name {
    @include sm {
      display: none;
    }
  }

  &__logout {
    background-color: transparent;
    cursor: pointer;
  }

}
</style>
